@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Nunito;
    src: url(assets/fonts/Nunito-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: Nunito;
    src: url(assets/fonts/Nunito-Bold.ttf);
    font-weight: 800;
}

@font-face {
    font-family: Nunito;
    src: url(assets/fonts/Nunito-Black.ttf);
    font-weight: 900;
}

@font-face {
    font-family: "Permanent Marker";
    src: url(assets/fonts/PermanentMarker-Regular.ttf);
}

html, body, #root {
    width: 100%;
    min-height: 100%;
    height: 100%;
    overflow-x: hidden;
}